<template>
  <app-layout>
    <div class="rounded-lg flex items-center flex-col my-5 justify-center">
      <div class="w-11/12 md:w-6/12 p-6 bg-white bg-opacity-80 shadow-xl">
        <h1
          class="font-bold text-lg md:text-2xl text-green-1002 text-center bold"
        >
          CONFIRME SEU REGISTRO
        </h1>
        <form @submit.prevent="submit">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="E-MAIL"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-5 text-lg md:text-xl focus:outline-none"
            v-model="email"
            autoComplete="off"
          />

          <input
            type="password"
            name="password"
            id="password"
            placeholder="SENHA"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg md:text-xl focus:outline-none"
            v-model="password"
            autoComplete="off"
          />

          <input
            type="password"
            name="password_confirmation"
            id="password-confirmation"
            placeholder="CONFIRMAÇÃO DA SENHA"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-5 text-lg md:text-xl focus:outline-none"
            v-model="password_confirmation"
            autoComplete="off"
          />

          <button
            type="submit"
            class="py-1 bg-green-1002 text-white text-center w-full mt-5 text-lg md:text-xl focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="submitting"
          >
            {{ buttonText }}
          </button>
        </form>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Register',

  title() {
    return `${process.env.VUE_APP_NAME} | Confirmação de Participação`;
  },

  components: {
    AppLayout
  },

  directives: { mask },

  data() {
    return {
      submitting: false,
      email: '',
      password: '',
      password_confirmation: ''
    };
  },

  computed: {
    ...mapGetters('register', {
      participant: 'participant'
    }),

    buttonText() {
      return this.submitting ? 'VERIFICANDO...' : 'VERIFICAR';
    }
  },

  methods: {
    ...mapActions('register', {
      registerConfirmation: 'registerConfirmation'
    }),

    submit() {
      this.submitting = true;

      this.registerConfirmation({
        participant: this.participant,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then(response => {
          this.$toast.success(response.data.message);

          this.$router.push({ name: 'login' });
        })
        .catch(({ response }) => {
          let errors = response.data.errors;

          Object.values(errors).forEach(error => {
            this.$toast.error(...error);
          });
        })
        .finally(() => (this.submitting = false));
    }
  }
};
</script>

<style scoped></style>
